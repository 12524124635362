import { Component, OnInit } from '@angular/core';
import { FormControl, AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { UtilService } from '../../core/util/util.service';
import { Router } from '@angular/router';
import { emailValidator } from '../../core/util/email-validator.directive';
import { AuthService } from '../auth.service';
import { ToastService } from '../../core/util/toast.service';
import { TranslateService } from '@ngx-translate/core';
import CustomValidation from '../../core/util/custom-validation';
import { APP_MODEL_DATA } from '../../core/const-data';
import { environment } from 'src/environments/environment';
import { LoaderService } from '../../core/util/loader.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})

export class SignUpComponent implements OnInit {
  registerForm!: FormGroup;
  NoWhitespaceStartRegExp = /^(\s+\S+\s*)*(?!\s).*$/
  digitsOnlyRegExp = new RegExp(APP_MODEL_DATA.DIGITS_ONLY_REGX);
  regularExpression = new RegExp(String.raw`^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()\?;,./{}|\":<>\[\]\\'~_\`])[a-zA-Z0-9!@#$%^&*()\?;,./{}|\":<>\[\]\\'~_\`]{${environment.passwordMinLength},${environment.passwordMaxLength}}$`);
  passwordMatch = false;
  emailMatch = false;
  countries = APP_MODEL_DATA.COUNTRIES;

  constructor(private translateService: TranslateService, private authService: AuthService, private utilService: UtilService, public router: Router, private formBuilder: FormBuilder, private toastService: ToastService, private loaderService: LoaderService) {
    this.utilService.checkLanguageConstraintForSignIn();
    let validToken = this.authService.isAuthenticated();
    if(validToken){
      this.router.navigate([ 'owners-center']);
    }
  }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      enteredEmail:[null,  [Validators.required,Validators.email,Validators.pattern(this.NoWhitespaceStartRegExp)]],
      confirmedEmail:[null,  [Validators.required,Validators.email,Validators.pattern(this.NoWhitespaceStartRegExp)]],
      enteredPassword:['',Validators.required],
      confirmedPassword:[null,Validators.required],
      enteredFirstName:['',[Validators.required,Validators.minLength(3),Validators.maxLength(250),Validators.pattern(this.NoWhitespaceStartRegExp)]],
      enteredLastName:['',[Validators.required,Validators.minLength(3),Validators.maxLength(250),Validators.pattern(this.NoWhitespaceStartRegExp)]],
      enteredAddress1:[null, [Validators.required,Validators.pattern(this.NoWhitespaceStartRegExp)]],
      enteredAddress2:[],
      enteredCity:[null, [Validators.required,Validators.pattern(this.NoWhitespaceStartRegExp)]],
      enteredState:[null, [Validators.required,Validators.pattern(this.NoWhitespaceStartRegExp)]],
      enteredPostalCode:[null, [Validators.compose([Validators.required, Validators.pattern(this.digitsOnlyRegExp)])]],
      enteredCountryCode: [null, Validators.required],
      enteredTelephone:[null,[Validators.required,Validators.pattern(this.NoWhitespaceStartRegExp),Validators.pattern(this.digitsOnlyRegExp)]],
      opt_1: [''],
      opt_2:[],
      enteredTerms:['', Validators.required],
      userLevel: ['user'],
      time_zone:[],        
    });
    this.registerForm.controls["enteredPassword"].setValidators([Validators.required,Validators.pattern(this.regularExpression)]);
    this.registerForm.controls["confirmedPassword"].setValidators(Validators.required);
  }

   // function to validate form fields for conditional display of validation messages
   isFieldValid(field: string) {
    if(field == 'confirmedPassword' && this.registerForm?.get('confirmedPassword')?.touched){
      return this.passwordMatch = this.registerForm?.get('confirmedPassword')?.value !== this.registerForm?.get('enteredPassword')?.value ? true : false;
    }
    if(field == 'confirmedEmail' && this.registerForm?.get('confirmedEmail')?.touched && this.registerForm?.get('enteredEmail')?.value){
      return this.emailMatch = this.registerForm?.get('confirmedEmail')?.value !== this.registerForm?.get('enteredEmail')?.value ? true : false;
    }
    return this.registerForm?.get(field)?.invalid && this.registerForm.get(field)?.touched;
  }

  // function to validate form instances
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  get registerFormControl(): { [key: string]: AbstractControl } {
    return this.registerForm.controls;
  }

  registerUserInfo(){
    if(!this.registerForm.valid){
      this.validateAllFormFields(this.registerForm);
    } else {
      this.registerForm?.get('enteredTerms')?.patchValue(this.registerForm?.get('enteredTerms')?.value === true ? 1 : 0);
      this.registerForm?.get('opt_1')?.patchValue(this.registerForm?.get('opt_1')?.value === true ? 1 : 0);
      this.registerForm?.get('opt_2')?.patchValue(this.registerForm?.get('opt_2')?.value === true ? 1 : 0);
      this.registerForm?.get('time_zone')?.patchValue(Intl.DateTimeFormat().resolvedOptions().timeZone);

      this.authService.signUp(this.registerForm.value)?.subscribe(
        (response) => {
          this.toastService.showSuccess(this.translateService.instant('lang.log_registered_user'), this.translateService.instant('lang.register'));
          this.loaderService.hide();
         // this.router.navigate(['signin']);
          this.authService.clearStorage();
        },
        (error) => {
          this.loaderService.hide();
          console.log(error.error);
        }
      );
    }
  }
}
