import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { catchError, forkJoin, Observable, of, retry, throwError, timer, concatMap } from 'rxjs';
import { ApiHandlerService } from './../core/util/api-handler.service';
import { ApiMethod } from '../core/const-data';
import { environment } from './../../environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastService } from '../core/util/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { UtilService } from '../core/util/util.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(public translateService: TranslateService,public router: Router,public jwtHelper: JwtHelperService, private http: ApiHandlerService, private toastService: ToastService) { }

  signIn(data: any) {
    return this.http.requestCall('', environment.loginApi, ApiMethod.POST, '', data)
  }

  signUp(data: any) {
    return this.http.requestCall(environment.apiUrl, '/signup', ApiMethod.POST, '', data)
  }

  resetPassword(data: any) {
    return this.http.requestCall(environment.apiUrl, '/email_reset_token', ApiMethod.POST, '', data)
  }

  forgotPassword(data: any) {
    return this.http.requestCall(environment.apiUrl, '/users/password/reset', ApiMethod.POST, '', data)
  }

  deleteAccount(data: any,secertKey: any) { 
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': secertKey
    });
    return this.http.requestCall(environment.rubyApiUrl, '/users/v1/delete-account', ApiMethod.POST,'', data, {headers: headers})
  }

  getAPIkey(key:string){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'API-KEY' : environment.AmzXApiKey
    });
    return this.http.requestCall(environment.rubyApiUrl, '/secret/v1/list?keys='+key, ApiMethod.GET,  '', '', {headers: headers} );
  }


  refreshToken(email: string, refresh_token?: string) {
    const data = {
      "email": email,
      "refresh_token": refresh_token
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': ''
    });
    return this.http.requestCall(environment.refreshApi, '', ApiMethod.POST, '', data)
  }

  userSession() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    return this.http.requestCall(environment.apiUrl, '/userId', ApiMethod.GET, '', '', { headers: headers });
  }

  userAccount(sessionId: string){
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    return this.http.requestCall(environment.apiUrl, '/users/'+sessionId+'/account', ApiMethod.GET, '', '', { headers: headers });
  }

  public isAuthenticated(): boolean {
    return this.getToken('idToken') !== "" ? true : false;
  }

  public add_year(dt: { setFullYear: (arg0: any) => string | number | Date; getFullYear: () => any; }, n: any) {
    return new Date(dt.setFullYear(dt.getFullYear() + n));
  }

  public setToken(token: any): void {
    localStorage.setItem('token', token);
  }

  public getLocalStoarge(key: any) {
    return localStorage.getItem(key);
  }
  public setLocalStorage(key: string, value: string) {
    localStorage.setItem(key, value);
  }
  public clearStorage() {
    let lang = this.getLocalStoarge("language");
    document.cookie = 'refreshToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie = 'idToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    localStorage.clear();
    
    if(lang){
      this.router.navigate(['signin'], { queryParams: {lang} });
    } else {
      lang = 'en';
      this.router.navigate(['signin'], { queryParams: { lang } });
    }
  }

  getToken(getCookieName: string) {
    let cookieName = getCookieName + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
      let cookieData = cookieArray[i];
      while (cookieData.charAt(0) == ' ') {
        cookieData = cookieData.substring(1);
      }
      if (cookieData.indexOf(cookieName) == 0) {
        return cookieData.substring(cookieName.length, cookieData.length);
      }
    }
    return "";
  }

  checkEngineerUser(){
    let userDetails = JSON.parse(this.getLocalStoarge('userInfo') || '{}');
    return userDetails?.userLevel == 'dev' ? true : false;
  }

  checkUserRole(userInfo: any): boolean {
    if ((userInfo?.userLevel == 'user' || userInfo?.userLevel == 'poolpro_user') && !userInfo.email.includes("@fluidra")) {
      return true;
    } else if (userInfo?.email?.includes("@fluidra") && userInfo?.userLevel == 'user') { // user
      return true;
    // } else if( userInfo?.userLevel == 'dev'){ // engineering
    //   return false;
    } else if( userInfo?.userLevel == 'support' || userInfo?.userLevel == 'support_user' || userInfo?.userLevel == 'dev' || userInfo?.userLevel == 'prog'){ // engineering
      this.clearStorage(); 
      return false;
    } else {
      this.clearStorage(); 
      this.toastService.showError('Given role is not supported','Error');
      return false;
    }
  }

}
