import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpInterceptor
} from '@angular/common/http';
import { Observable,catchError, finalize, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import {UtilService} from './../core/util/util.service';
import { ToastService } from '../core/util/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import { LoaderService } from '../core/util/loader.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(public translateService: TranslateService,private authService: AuthService,private utilService: UtilService, private toastService: ToastService,private loaderService: LoaderService) {}
  // TODO: This is  todo implement on 2nd level token handling for cognito

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.loaderService.show();
    const token = this.authService.getToken('idToken');
    const checkForRefresh = !request.url.includes('refresh')
    const checkForCognito = !request.url.includes('amazoncognito')
    if(checkForRefresh &&  token){
      request = request.clone({
          headers: request.headers.set(
              'Authorization', `Bearer ` + token
          )
      });
  }
//   if(request.url.includes('secret/v1') &&  !token){
//     request = request.clone({
//         headers: request.headers
//         .set('Content-Type', 'application/json' )
//         .set('API-KEY', environment.AmzXApiKey )
//         .set("Access-Control-Allow-Origin", "**")
//         .set("Access-Control-Allow-Methods", "GET")
//     });
// }
    // return next.handle(request);
    return next.handle(request).pipe(
      finalize(() => {
      //  this.loaderService.hide();
      }),
      catchError((error: HttpErrorResponse) => {
      this.loaderService.hide();
      let message = '';
      if(error.status == 401 ){
        if(error?.error.message){
          this.toastService.showError(error?.error.message, this.translateService.instant('lang.error'));
        } else {
          this.authService.clearStorage();
          error.error === "Authorization Required" ? this.toastService.showError(this.translateService.instant('lang.sign_in_error'),error.error): this.toastService.showError(error?.message,error.message);
        }
        // localStorage.clear()
        // window.location.reload();
        return throwError(() => error);
      } else if (error.error === "User is disabled"){
        this.toastService.showError(this.translateService.instant('lang.log_disabled_user'),error.error);
        // localStorage.clear()
        this.authService.clearStorage();
        return throwError(() => error);
      } else if (error.error === "not_supported"){
        // this.toastService.showError(this.translateService.instant('lang.log_disabled_user'),error.error);
        alert(`You will be redirected to ${environment.legacyUrl} for programmer's login. Click 'OK' to proceed`);
        window.location.href = environment.legacyUrl;
        //localStorage.clear()
        // register_error
        this.authService.clearStorage();
        return throwError(() => error);
        // TODO: Make it generic 

      } 
      else if (error.error?.messages[0]?.text){
        const errorMessageKey = error.error?.messages[0]?.text;
        const translationKey = `lang.${errorMessageKey}`;
        this.toastService.showError(this.translateService.instant(translationKey), this.translateService.instant('lang.error'));
        return throwError(() => error);
      }
       else{
        console.log(error.error?.messages[0]?.text);
        message = error.error?.messages[0]?.text;
        message = message == "serial_number_error" ? this.translateService.instant('lang.serial_number_error') : message
        this.toastService.showError(message, this.translateService.instant('lang.error'));
        this.loaderService.hide();
        return throwError(() => error);
      }

    }))
  }
}
