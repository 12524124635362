export const environment = {
    ENABLE_PROD_MODE: false,
    apiUrl: "https://prm.zodiac-test.com/v2",
    rubyApiUrl: "https://prm.zodiac-test.com",
    AmzXApiKey:'XN4KXET4KK124BS7JI8RBQ6JELDYCB8P',
    webTouch:"https://webtouch.zodiac-test.com",
    domain:"site.zodiac-test.com",
    loginApi:"https://test.zodiac-io.com/users/v1/login",
    legacyUrl:"https://www.zodiac-test.com",
    refreshApi: "https://test.zodiac-io.com/users/v1/refresh4InviteCode",
    platformIoUrl:"https://test.zodiac-io.com/devices/v2",
    retryAttempts:2,
    platformIoUrlV1:"https://test.zodiac-io.com/devices/v1",
    passwordMinLength:8,
    passwordMaxLength:32,
    supportUrl:"https://support.zodiac-test.com",
    userAuthPwdFormDomain: "https://test.zodiac-io.com",
    chatBotcss:[
        'https://chatbot.zodiac-test.com/FAQChatbot-UI/Ver1.0/css/botwindow.css',
        'https://chatbot.zodiac-test.com/FAQChatbot-UI/Ver1.0/css/swiper.min.css'
    ],
    chatBotjs:[
        'https://sdk.amazonaws.com/js/aws-sdk-2.923.0.min.js',
        'https://unpkg.com/axios/dist/axios.min.js',
        'https://chatbot.zodiac-test.com/FAQChatbot-UI/Ver1.0/js/swiper.min.js',
        'https://chatbot.zodiac-test.com/FAQChatbot-UI/Ver1.0/js/botconfig.js',
        'https://chatbot.zodiac-test.com/FAQChatbot-UI/Ver1.0/js/botrender.js',
        'https://chatbot.zodiac-test.com/FAQChatbot-UI/Ver1.0/js/botwindow.js'
      ]
}   