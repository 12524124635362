import { Component } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { UserService } from '../user.service';
import { APP_MODEL_DATA } from '../../core/const-data';

import { ModalService } from '../../core/util/modal.service';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { LoaderService } from '../../core/util/loader.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../auth/auth.service';
import { ToastService } from '../../core/util/toast.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent { 
rows: any;
p:any;
returnRow : any;
filteredData: any[] = []; // Data to be displayed after filtering and pagination
expanded: any = {};
selected = [];
timeout: any;
loadTable = false;
// pagination property
pageSize = APP_MODEL_DATA.pageSize;
currentPage = APP_MODEL_DATA.CURRENT_PAGE;
page: number = 1;
editedItem: any;
count: number = 0;
// search propery
search=''
// device Details
deviceDetails:any;
loadDetails = false;
// device Edit
editForm!: FormGroup;
isLogin = false;
enableSearch = false;
enablePagination = false;


  constructor(private router: Router,private formBuilder: FormBuilder,private modalService: ModalService, private translateService: TranslateService,private toastService: ToastService, private userService: UserService,private loaderService: LoaderService, private authService: AuthService){
    this.isLogin = this.authService.isAuthenticated();
    if(this.isLogin && !this.authService.checkEngineerUser()){
      this.getDeviceList();
     this.editForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(18), Validators.minLength(2)]],
      userId: ['']
    });
    } else if(this.isLogin && this.authService.checkEngineerUser()){
      this.router.navigate(['engineering']);
    }
     else {
      //  this.router.navigate(['signin']);
        this.authService.clearStorage();
    }
  }

  getDeviceList(){
    this.userService.getLocation()?.subscribe((device:any)=>{
     this.tableRowInit(device)
   });
  }

  tableRowInit(device:any){
    this.rows = device?.locations;
    // console.log(device?.locations)
    // this.rows = this.deviceData.locations;
    this.enableSearch = this.rows?.length > 4;
    this.enablePagination = this.rows?.length > 4;
    this.rows.map((i: { [x: string]: boolean; })=>{i['isExpand']=false,i['isEdit']=false});
    this.returnRow = this.rows.slice(0,this.pageSize);
    this.count = this.rows.length; 
    this.loadTable =true
  }

  launchWebTouchClick(device: any, debug: string):void {
    if(device?.device_type == 'iaqua'){
      this.userService.launchWebTouchClick(device, debug);
    }
  }

  get name() {
    return this.editForm.get('name');
  }

  isFieldValid(field: string) {
    return this.editForm?.get(field)?.invalid && this.editForm.get(field)?.touched;
  }

  deviceStatus(device:any,i:number){
    // this.rows.map((i: { [x: string]: boolean; })=>{i['isExpand']=false,i['isEdit']=false});
    //this.rows[i]['isExpand']= !this.rows[i]['isExpand'];
    device['isExpand']= !device['isExpand'];
    if(device['isExpand']== true){
      this.loadDetails = false;
      device['isEdit'] = false;
      this.editForm.reset();
      this.userService.getDeviceStatus(device.serial_number)?.subscribe(
        (details:any)=>{
        // console.log(details);
        this.deviceDetails = details.status;
        this.loadDetails = device['isExpand'];
        this.loaderService.hide();
      },
      (error:any)=>{
       console.log(error);
       device['isExpand']= false;
       this.loadDetails = false;
       this.loaderService.hide();
       this.deviceDetails = '';
      })
    } else {
      this.loadDetails = device['isExpand'];
    }
  }

  deviceEdit(device:any,i:number){
    device['isEdit']= !device['isEdit'];
    if(device['isEdit']== true){
      device['isExpand'] = false;
    }else {
      this.editForm.reset();
    }
  }

  editNameInfo(device:any){
    if(this.editForm.valid){
      this.editForm.controls["userId"].setValue(this.authService.getLocalStoarge('sessionId'));
      this.userService.updateDeviceName(this.editForm.value,device.serial_number)?.subscribe((details)=>{
        device['isEdit']= !device['isEdit'];
        this.editForm.reset();
        this.loaderService.hide();
        this.tableRowInit(details);
        this.toastService.showSuccess('', this.translateService.instant('lang.modal_saving_success'));
      });
    }
  }

  pageChanged(page:any): void {
    this.page = page;
    const startItem = (this.page - 1) * this.pageSize;
    const endItem = this.page * this.pageSize;
    // console.log(endItem);
    this.returnRow = this.rows.slice(startItem, endItem);
  }

  reset(){
    this.search = '';
    this.returnRow = this.rows.slice(0,this.pageSize);
  }

  // * Open model pop up check
  openModal(device:any): void {  // delete row modal pop up
    const title = 'Delete: ' + device?.Name;
    const data = this.translateService.instant('lang.alert_delete_confirm')
    const content = data;
    const isHTML = false;
    const buttonsConfig = [
      { class:"btn btn-danger",label: this.translateService.instant('lang.delete'), action: () => this.delete(device) }
    ];
    const onClose = () => {};
    const onShow = () => {};
    const modalConfig: ModalOptions = {
      class: 'modal-dialog-centered', // ? Center modal vertically
      backdrop: 'static', // ? Prevent modal from closing on backdrop click
      keyboard: false, // ? Prevent modal from closing on escape key press
    };
    this.modalService.openModal(title, content,modalConfig, isHTML, buttonsConfig, onClose, onShow);
  }

  delete(device:any){
    this.userService.deleteDevice(device.serial_number)?.subscribe((details)=>{
      this.loaderService.hide();
      this.modalService.closeModal();
      this.tableRowInit(details);
      this.toastService.showSuccess('', this.translateService.instant('lang.modal_saving_success'));
    });
  }

}
