import { Component, OnInit } from '@angular/core';
import { FormControl, AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoaderService } from '../../core/util/loader.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../auth.service';
import { UtilService } from '../../core/util/util.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../core/util/toast.service';
import { ModalService } from '../../core/util/modal.service';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { APP_MODEL_DATA } from '../../core/const-data';

interface DeleteUser {
  deleteToken: string,
  accountEmail: string,
  password: string,
  showPassword: boolean
}

@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.css']
})
export class DeleteAccountComponent implements OnInit   {
  deleteUser : DeleteUser;
  delete_password_token :any = '';
  email :any = '';
  lang : any = '';
  passwordForm!: FormGroup;

  constructor(private route: ActivatedRoute,private modalService: ModalService,private translateService: TranslateService, private authService: AuthService, private utilService: UtilService, public router: Router, private formBuilder: FormBuilder, private toastService: ToastService, private loaderService: LoaderService) {
    this.utilService.checkLanguageConstraintForSignIn();
    this.deleteUser = {} as DeleteUser;
  }
  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      this.delete_password_token = params.get('delete_password_token');
      this.email = params.get('email');
      this.lang = params.get('lang');
    });
    // console.log(this.reset_password_token);
     this.passwordForm= this.formBuilder.group({  
      password:[this.deleteUser.password,Validators.required]   
     });
  }

  isFieldValid(field: string) {
    return this.passwordForm?.get(field)?.invalid && this.passwordForm.get(field)?.touched;
  }
  
  get password() {
    return this.passwordForm.get('password')!;
  }

  public validate(): void {
    if (this.passwordForm.invalid) {
      for (const control of Object.keys(this.passwordForm.controls)) {
        this.passwordForm.controls[control].markAsTouched();
      }
      return;
    } else {
      this.deleteUser = this.passwordForm.value;
      this.deleteUser.deleteToken = this.delete_password_token;
      this.deleteUser.accountEmail = this.email;
     // console.log(this.deleteUser);
      this.getSecertKey();
    }
  }

  getSecertKey(){
    this.authService.getAPIkey(APP_MODEL_DATA.RPM_DELETE_ACC_KEY)?.subscribe(
      (response:any)=>{
        if(response.secret_values[0].secret_key=='RPM_DELETE_ACCOUNT_API_KEY'){
          this.deleteUserDetail(response.secret_values[0].secret_value);
        }
      },
      (error)=>{
        console.log(error)
      }
    )
  }

  deleteUserDetail(secertKey:string): void {
    // {"reset_password_token":"CVszRcyNzKkAVVPoG60_","newPassword":"System@12345","confirmPassword":"System@12345"}
    // this.openModal();
    // delete credentials.showpassword;

    this.authService.deleteAccount(this.deleteUser,secertKey)?.subscribe(
      (response: any) => {
        if(response?.requestStatus == "SUCCESS"){
          this.openModal();
        }
      },
      (error) => {
        console.log(error.message);
      }
    );
  }

  openModal(): void { 
    const title = 'Success';
    const data = this.translateService.instant('lang.delete_account_success_msg')
    const content = data;
    const isHTML = true;
    const buttonsConfig = [
      {  class:"btn btn-prime",label: 'Close', action: () => this.closeTab() }
    ];    const onClose = () => {};
    const onShow = () => {};
    const modalConfig: ModalOptions = {
      class: 'modal-dialog-centered', // ? Center modal vertically
      backdrop: 'static', // ? Prevent modal from closing on backdrop click
      keyboard: false, // ? Prevent modal from closing on escape key press
    };
    this.loaderService.hide();
    this.modalService.openModal(title, content,modalConfig, isHTML, buttonsConfig, onClose, onShow);
}

closeTab(){
  window.close();
}

}
